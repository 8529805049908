<template>
  <v-container
    id="regular-tables"
    fluid
    tag="section"
  >
    <base-v-component
      heading="Account Status"
    />
    <template v-if="items.length > 0">
      <v-row>
        <v-col>
          <h2>
            {{ items.length }} Accounts
          </h2>
        </v-col>

        <v-tooltip right>
          <template v-slot:activator="{ on }">
            <v-col class="text-right">
              <download-excel
                :data="parsedItems"
                type="csv"
                :name="fileName"
              >
                <v-icon
                  large
                  color="green darken-2"
                  v-on="on"
                >
                  mdi-microsoft-excel
                </v-icon>
              </download-excel>
            </v-col>
          </template>
          <span>Download</span>
        </v-tooltip>
      </v-row>
    </template>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-data-table
            :loading="loading"
            loading-text="Loading... Please wait"
            :headers="headers"
            :items="items"
            :search="search"
            :items-per-page="50"
            :sort-by="['make']"
          >
            <template #item.dms_sales_imported_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.dms_service_imported_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.dms_inventory_imported_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
            <template #item.website_scraped_at="{value}">
              {{ formatIsoDate(value, true) }}
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <v-dialog
      v-model="loading"
      hide-overlay
      persistent
      width="300"
    >
      <v-card
        color="primary"
        dark
      >
        <v-card-text>
          Loading
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0"
          />
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showErrorDialog"
      persistent
      max-width="600"
      @keydown.esc="showErrorDialog = false"
    >
      <error-message
        :error="error"
        @closeErrorDialog="closeErrorDialog"
      />
    </v-dialog>    
  </v-container>
</template>
<script>
  import NetworkService from '@/services/NetworkService.js'
  import ErrorMessage from '@/components/ErrorMessage.vue'
  import AdminService from '@/services/AdminService.js'
  import { mapGetters } from 'vuex'
  import { pick } from '@/mixins/exportMixin.js'
  import { formatterMixin } from '@/mixins/formatterMixin.js'
  import { navigationMixin } from '@/mixins/navigationMixin.js'

  export default {
    name: 'AccountStatus',
    components: {
      ErrorMessage,
    },    
    mixins: [formatterMixin, navigationMixin],
    data () {
      return {
        showErrorDialog: false,
        error: '',
        items: [],
        settings: {},
        search: '',
        loading: true,
        headers: [
          { text: 'Account ID', value: 'account_id' },
          { text: 'Name', value: 'account_name', align: 'center' },
          { text: 'DMS Sales Last Imported', value: 'dms_sales_imported_at', align: 'center' },
          { text: 'DMS Service Last Imported', value: 'dms_service_imported_at', align: 'center' },
          { text: 'DMS Inventory Last Imported', value: 'dms_inventory_imported_at', align: 'center' },
          { text: 'Website Last Scraped', value: 'website_scraped_at', align: 'center' },
        ],
      }
    },
    computed: {
      parsedItems () {
        const parsedHeaders = this.headers.map(h => h.value)
        if (this.items) {
          return this.items.map(i => pick(i, parsedHeaders))
        }
        return []
      },
      fileName () {
        return `${this.accountName
          .toLowerCase()
          .replace(' ', '_')}_account_status.xls`
      },
      ...mapGetters(['accountId', 'alertSettings', 'accountName']),
    },
    created () {
      this.loadAccountStatus()
    },
    methods: {
      closeErrorDialog: function () {
        this.showErrorDialog = false
      },      
      loadAccountStatus: function () {
        this.items = []
        this.loading = true
        AdminService.getAccountStatuses()
          .then(response => {
            this.items = response.data
            this.loading = false
          })
          .catch(error => {
            this.loading = false
            this.error = NetworkService.getErrorMessage(error)
            this.showErrorDialog = true
          })
      },
    },
  }
</script>
